html {
    scroll-behavior: smooth;
}

.min-vh-70 {
    min-height: 70vh !important;
}

.min-vh-50 {
    min-height: 50vh !important;
}

.background-img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.image-object-fit {
    object-fit: cover;
    object-position: center;
}

.g-4 {
    gap: 1rem;
}